import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';
import { formatPercentage } from '../../../../../../../utils/number';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useGetPropertyFinanceInformationQuery,
  useGetPropertyFinanceSaleSummaryQuery,
} from '../../../api/api.propertyFinance';
import { getValuationDifferencePercent } from '../utils.valuation';
import ValuationTrendChart from './ValuationTrendChart';
import FinanceIcon from '../../../components/FinanceIcon';
import BarChart from '../../../../../../../components/icons/BarChart';

type LatestValuationInfoProps = {
  showIcon?: boolean;
  chartMinHeight?: number;
};

const LatestValuationInfo: FC<LatestValuationInfoProps> = ({
  showIcon = false,
  chartMinHeight = 200,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeInformation,
    isLoading: financeInformationIsLoading,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: financeSaleSummary,
    isLoading: financeSaleSummaryIsLoading,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const isLoading = financeInformationIsLoading || financeSaleSummaryIsLoading;

  const latestMovestaValuation = financeInformation?.latestMovestaValuation;
  const marketPrice = latestMovestaValuation?.expectation || 0;

  const purchasePrice = financeSaleSummary?.purchasePrice;
  const purchasePriceAmount = purchasePrice?.amount || 0;

  const percent = getValuationDifferencePercent(marketPrice, purchasePriceAmount);

  return (
    <>
      <Stack
        alignItems="center"
        flexDirection="row"
        gap={2}
        justifyContent="flex-start"
      >
        {showIcon && (
          <FinanceIcon
            Icon={BarChart}
            iconBackgroundColor={theme.palette.primary.lighter}
            iconColor={theme.palette.primary.dark}
          />
        )}
        <Stack alignItems="flex-start" gap={0.5}>
          <HIDTypography>
            {t('finances:latest_valuation')}
          </HIDTypography>
          <HIDTypography
            isLoading={isLoading}
            skeletonWidth={140}
            variant="h4"
          >
            {formatMoney(latestMovestaValuation?.expectation || 0, false)}
          </HIDTypography>
          <Stack
            alignItems="center"
            flexDirection="row"
            sx={{ gap: 1 }}
          >
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={72}
              sx={{ color: percent > 0 ? theme.palette.primary.main : theme.palette.error.main }}
            >
              {formatPercentage(Math.abs(percent), { withPrefixSign: true, wholeNumber: true })}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={88}
              variant="subtitle1"
            >
              {`${formatMoney(purchasePriceAmount, false)},`}
            </HIDTypography>
            <HIDTypography
              isLoading={isLoading}
              skeletonWidth={72}
            >
              {t('finances:latest_valuation_finance_info_text')}
            </HIDTypography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ height: chartMinHeight }}>
        <ValuationTrendChart percent={isLoading ? 50 : percent * 100} />
      </Stack>
    </>
  );
};

export default LatestValuationInfo;
