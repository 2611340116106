import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getValuationOverviewPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_OVERVIEW,
  { propertyId },
);

export const getCreateBrokerValuationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_CREATE,
  { propertyId },
);

export const getUpdateBrokerValuationPath = ({ id, propertyId }: { id: string } & PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_UPDATE,
  { id, propertyId },
);

export const getBrokerValuationsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATIONS,
  { propertyId },
);

export const getValuationHistoryPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_HISTORY,
  { propertyId },
);

export const getBookValuationMeetingPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.VALUATION_BOOK_MEETING,
  { propertyId },
);
