import React, {
  FC,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'usehooks-ts';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  HIDBlob,
  HIDBlobModify,
  ModifyActionType,
  HIDEntityId,
} from '@house-id/houseid-types/dist/common';
import {
  Receipt,
  ReceiptInitialData,
  ReceiptLine,
} from '@house-id/houseid-types/dist/content/receipt';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { useGetReceiptCategoryName } from '../../utils.receipt';
import { ALL_RECEIPTS_CATEGORY } from '../../constants.receipt';
import {
  useCreateReceiptMutation,
  useGetAllReceiptCategoriesQuery,
  useGetReceiptWithCache,
  useRateScanReceiptMutation,
  useUpdateReceiptMutation,
} from '../../api/api.receipt';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import HIDTextField from '../../../../../../../../components/HIDTextField';
import HIDFormDatePicker from '../../../../../../../../components/datePicker/HIDFormDatePicker';
import HIDFormSelect from '../../../../../../../../components/HIDFormSelect';
import { getHandleSetField } from '../../../../../../../../utils/form';
import ReceiptLineForm from './components/ReceiptLineForm';
import ManualAmount from './components/ManualAmount';
import ReceiptLinesTotalAmount from './components/ReceiptLinesTotalAmount';
import HIDAddLinkButton from '../../../../../../../../components/HIDAddLinkButton';
import {
  getReceiptCategoriesPath,
  getReceiptCategoryPath,
  getReceiptPath,
} from '../../navigation.receipt';
import HIDFormFreeSelect from '../../../../../../../../components/HIDFormFreeSelect';
import {
  useGetBoughtByQuery,
  useGetPurchasePlacesQuery,
} from '../../../../api/api.content';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { ReceiptFormFields } from './types';
import ContentFileViewer from '../../../../components/ContentFileViewer';
import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../utils/routes';
import {
  useGetContentFileBlobsByIdsQuery,
} from '../../../ContentFile/api/api.contentFile';
import { ContentFile } from '../../../ContentFile/types.contentFile';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../../components/CreateContentPageBottomToolbar';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import {
  EntityConnectionsCommonRouteState,
  EntityUId,
} from '../../../../types/types.content';
import { IdPropRoute } from '../../../../../../../../types/route';
import useEntitySuggestionSection from '../../../Suggestion/hooks/useEntitySuggestionSection';
import useManageConnectionAfterCreateOrUpdate from '../../../../hooks/useManageConnectionAfterCreateOrUpdate';
import { removeByIndex } from '../../../../../../../../utils/array';
import useGetLogAnalyticsEvent from '../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { AnalyticsEvent } from '../../../../../../../Analytics/types.analytics';
import InfoBox from '../../../../../../../../components/InfoBox';
import {
  parseIntNumber,
  parseFloatNumber,
} from '../../../../../../../../utils/string';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useOpenScanReceiptOrSubscriptionDialog from '../../hooks/useOpenScanReceiptOrSubscriptionDialog';

const isSameEntity = (a: Partial<HIDEntityId>, b: Partial<HIDEntityId>) => a.id === b.id;

const getTotalAmount = R.pipe(
  R.map((line: { quantity: number; pricePerUnit: number }) => line.quantity * line.pricePerUnit),
  R.sum,
);

const getTotalBasicImprovement = R.pipe(
  R.map((line: { basicImprovementValue?: number | null }) => line.basicImprovementValue || 0),
  R.sum,
);

const getTotalRepairImprovement = R.pipe(
  R.map((line: { repairImprovementValue?: number | null }) => line.repairImprovementValue || 0),
  R.sum,
);

enum EnterAmountType {
  PER_RECEIPT_LINE = 'PER_RECEIPT_LINE',
  FOR_ENTIRE_RECEIPT = 'FOR_ENTIRE_RECEIPT',
}

const NAME_MAX_LENGTH = 128;

type CreateUpdateReceiptRouteParams = IdPropRoute & { category?: string };
type CreateUpdateReceiptRouteQueryParams = {
  contentFileIds?: string;
  forceScanReceipt?: boolean;
  suggestionKey?: string;
};

const CreateUpdateReceipt: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const navigate = useNavigate();

  const {
    routeParams: {
      id: receiptId,
      category: categoryId,
    },
    queryParams: {
      contentFileIds: contentFileIdsParam,
      forceScanReceipt: forceScanReceiptParam,
      suggestionKey,
    },
    state,
  } = useNavigationParamsAndState<
  CreateUpdateReceiptRouteParams,
  CreateUpdateReceiptRouteQueryParams,
  EntityConnectionsCommonRouteState<ReceiptInitialData>
  >();

  const contentFileIds = contentFileIdsParam?.split(',');
  const forceScanReceipt = Boolean(forceScanReceiptParam);

  const connections = state?.connections || [] as Array<EntityUId>;
  const receiptInitialData = state?.initialData as ReceiptInitialData;
  const hasConnections = !R.isEmpty(connections);

  const isUpdate = Boolean(receiptId);

  const theme = useTheme();

  const { t } = useTranslation(['common', 'forms_common', 'receipts']);

  const { isDownMd } = useBreakPointsSizes();
  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const {
    data: propertyId,
    isLoading: propertyIsLoading,
  } = useGetCurrentPropertyId();

  const {
    receipt,
    isLoading: receiptIsLoading,
  } = useGetReceiptWithCache({ propertyId, receiptId });

  const [openScanReceiptDialog, preventOpeningScanReceiptDialog] = useOpenScanReceiptOrSubscriptionDialog();

  const { data: categories = [] } = useGetAllReceiptCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const { data: places = [] } = useGetPurchasePlacesQuery(propertyId ? { propertyId } : skipToken);
  const { data: boughtBy = [] } = useGetBoughtByQuery(propertyId ? { propertyId } : skipToken);

  const categoryName = useGetReceiptCategoryName(categoryId, categories);

  const [enterAmountType, setEnterAmountType] = useState(
    !isUpdate || receipt?.lines?.length || receiptInitialData?.lines?.length
      ? EnterAmountType.PER_RECEIPT_LINE
      : EnterAmountType.FOR_ENTIRE_RECEIPT,
  );

  const [createReceipt, { isLoading: receiptIsCreating }] = useCreateReceiptMutation();
  const [updateReceipt, { isLoading: receiptIsUpdating }] = useUpdateReceiptMutation();

  const [blobs, setBlobs] = useState<Array<HIDBlob | HIDBlobModify>>([]);
  const [blobToScan, setBlobToScan] = useState<HIDBlob | undefined>();
  const [inferenceId, setInferenceId] = useState<string | undefined>();

  useEffect(() => {
    if (receipt) {
      setEnterAmountType(receipt.lines?.length ? EnterAmountType.PER_RECEIPT_LINE : EnterAmountType.FOR_ENTIRE_RECEIPT);
    }
    if (!blobs.length) {
      setBlobs(receipt?.blobs || []);
    }
  }, [receipt]);

  const { data: contentFileBlobs } = useGetContentFileBlobsByIdsQuery({ propertyId, contentFileIds });

  const isPerReceiptLine = enterAmountType === EnterAmountType.PER_RECEIPT_LINE;

  const lineSchema = Yup.object<ReceiptLine>({
    id: Yup.string().optional(),
    name: Yup.string()
      .test(
        'required',
        t('forms_common:field_mandatory'),
        (value) => Boolean(value || !isPerReceiptLine),
      ),
    quantity: Yup.string()
      .test(
        'required',
        t('forms_common:field_mandatory'),
        (value) => (value !== undefined && parseIntNumber(value, 0) > 0) || !isPerReceiptLine,
      ),
    pricePerUnit: Yup.string()
      .test(
        'required',
        t('forms_common:field_mandatory'),
        (value) => (value !== undefined && parseFloatNumber(value, 0) >= 0) || !isPerReceiptLine,
      ),
    isImprovement: Yup.boolean(),
    basicImprovementValue: Yup.number().optional().nullable(),
    repairImprovementValue: Yup.number().optional().nullable(),
    articleNumber: Yup.string().optional().nullable(),
  }).required();

  const schema = Yup.object({
    name: Yup.string()
      .required(t('forms_common:field_mandatory'))
      .max(NAME_MAX_LENGTH, t('forms_common:forms_common_maximum_characters_limit', { limit: NAME_MAX_LENGTH })),
    date: Yup.string().required(t('forms_common:field_mandatory')),
    boughtBy: Yup.string().optional().nullable(),
    categoryId: Yup.string().required(t('forms_common:field_mandatory')),
    invoiceNumber: Yup.string().optional().nullable(),
    place: Yup.string().optional().nullable(),
    annotation: Yup.string().optional().nullable(),
    amount: Yup.string()
      .test(
        'required',
        t('forms_common:field_mandatory'),
        (value) => (value !== undefined && parseFloatNumber(value, 0) >= 0) || isPerReceiptLine,
      ),
    isImprovement: Yup.boolean(),
    basicImprovementValue: Yup.string(),
    repairImprovementValue: Yup.string(),
    lines: Yup.array<ReceiptLine>().of(lineSchema).optional().nullable(),
    saveMode: Yup.string().optional().nullable(),
  });

  const handleGoBack = (entity?: HIDEntityId) => receiptId
    ? navigateBackOr(getPathWithPropertyIdOrInit(getReceiptPath, { propertyId, categoryId, id: receiptId }))
    : entity?.id
      ? navigate(getPathWithPropertyIdOrInit(getReceiptPath, { propertyId, id: entity.id }), { replace: true })
      : navigateBackOr(getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId }));

  const [openRateScanActionDialog] = useDialog(DialogNames.RATE_ACTION_DIALOG);

  const [rateScanReceipt] = useRateScanReceiptMutation();

  const handleGoBackWithRateScanDialog = (entity: Receipt) => new Promise<Receipt>((resolve) => {
    if (propertyId && blobToScan && inferenceId) {
      openRateScanActionDialog({
        title: t('receipts:receipts_rate_scanning_dialog_title'),
        description: t('receipts:receipts_rate_scanning_dialog_description'),
        onSubmit: (rating) => {
          rateScanReceipt({
            propertyId,
            blobId: blobToScan.id,
            rating,
            inferenceId,
          });
          resolve(entity);
        },
        onCancel: () => resolve(entity),
      });
      logAnalyticsEvent({
        event: AnalyticsEvent.SCAN_RECEIPT,
        hidCategory: EntityType.RECEIPT,
      });
    } else {
      resolve(entity);
    }
  });

  const [fieldRefs] = useState<Record<string, React.MutableRefObject<HTMLInputElement | undefined>>>({
    amount: useRef<HTMLInputElement>(),
    invoice_number: useRef<HTMLInputElement>(),
    date: useRef<HTMLInputElement>(),
    place: useRef<HTMLInputElement>(),
  });

  const {
    SuggestionSection,
    refetchSuggestions,
  } = useEntitySuggestionSection({
    entityId: receiptId,
    entityType: EntityType.RECEIPT,
    fieldRefs,
    initialSuggestion: suggestionKey,
  });

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.RECEIPT,
    connections,
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (values: WithSaveMode<ReceiptFormFields>) => {
    const { saveMode } = values;

    const lines = (values.lines || [])
      .map((line) => ({
        ...line,
        quantity: parseIntNumber(line.quantity, 0),
        pricePerUnit: parseFloatNumber(line.pricePerUnit, 0),
        basicImprovementValue: line.basicImprovementValue ? parseFloatNumber(line.basicImprovementValue) : null,
        repairImprovementValue: line.repairImprovementValue ? parseFloatNumber(line.repairImprovementValue) : null,
      }));

    const amountData = isPerReceiptLine
      ? {
        amount: values.amount ? parseFloatNumber(values.amount) : getTotalAmount(lines),
        basicImprovementValue: getTotalBasicImprovement(lines),
        repairImprovementValue: getTotalRepairImprovement(lines),
      }
      : {
        amount: parseFloatNumber(values.amount),
        basicImprovementValue: values.basicImprovementValue ? parseFloatNumber(values.basicImprovementValue) : null,
        repairImprovementValue: values.repairImprovementValue ? parseFloatNumber(values.repairImprovementValue) : null,
      };

    if (isUpdate && receiptId) {
      // UPDATE
      const receiptLines = isPerReceiptLine
        ? [
          ...lines.map((line, index) => ({
            ...line,
            action: line?.id ? ModifyActionType.UPDATE : ModifyActionType.CREATE,
            ordering: index,
          })),
          ...R.differenceWith(isSameEntity, receipt?.lines || [], lines)
            .map((line) => ({ ...line, action: ModifyActionType.DELETE })),
        ]
        : receipt?.lines?.map((line) => ({ ...line, action: ModifyActionType.DELETE }));

      const newReceipt = {
        id: receiptId,
        ...values,
        ...amountData,
        blobs: [
          ...R.difference(receipt?.blobs || [], blobs)
            .map((blob) => ({ ...blob, action: ModifyActionType.DELETE })),
          ...R.difference(blobs, receipt?.blobs || [])
            .map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
        ],
        lines: receiptLines,
      };

      updateReceipt(newReceipt)
        .unwrap()
        .then((updatedReceipt) => {
          refetchSuggestions();
          return handleGoBackWithRateScanDialog(updatedReceipt);
        })
        .then((updatedReceipt) => afterUpdate(updatedReceipt, saveMode));
    } else {
      // CREATE
      const newReceipt = {
        ...values,
        ...amountData,
        blobs: blobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
        lines: isPerReceiptLine
          ? lines.map((line, index) => ({ ...line, action: ModifyActionType.CREATE, ordering: index }))
          : [],
      };

      createReceipt(newReceipt)
        .unwrap()
        .then((createdReceipt) => handleGoBackWithRateScanDialog(createdReceipt))
        .then((createdReceipt) => {
          logAnalyticsEvent({
            event: AnalyticsEvent.CREATE_RECEIPT,
            hidCategory: EntityType.RECEIPT,
          });
          afterCreate(createdReceipt, saveMode);
        });
    }
  };

  const formik = useFormik<ReceiptFormFields>({
    initialValues: {
      propertyId: propertyId || '',
      name: receipt?.name || receiptInitialData?.name || '',
      date: receipt?.date || receiptInitialData?.date || '',
      boughtBy: receipt?.boughtBy,
      categoryId: (
        isUpdate
          ? receipt?.categoryId
          : categoryId === ALL_RECEIPTS_CATEGORY ? '' : categoryId
      ) || '',
      invoiceNumber: receipt?.invoiceNumber || receiptInitialData?.invoiceNumber,
      place: receipt?.place || receiptInitialData?.place,
      annotation: receipt?.annotation || receiptInitialData?.annotation,
      amount: receipt?.amount || receiptInitialData?.amount,
      isImprovement: Boolean(
        receipt?.basicImprovementValue
        || receipt?.repairImprovementValue
        || receiptInitialData?.basicImprovementValue,
      ),
      basicImprovementValue: receipt?.basicImprovementValue || receiptInitialData?.basicImprovementValue,
      repairImprovementValue: receipt?.repairImprovementValue,
      lines: isUpdate
        ? receipt?.lines?.map((line: ReceiptLine) => ({
          ...line,
          isImprovement: Boolean(line.basicImprovementValue || line.repairImprovementValue),
        }))
        : receiptInitialData?.lines || [{ quantity: 1 } as ReceiptLine],
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const [formTouched, setFormTouched] = useState(false);

  const handleSetField = getHandleSetField<ReceiptFormFields>(formik);

  const handleEnterAmountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enterAmountType = (event.target as HTMLInputElement).value as EnterAmountType;
    setEnterAmountType(enterAmountType);
    if (enterAmountType === EnterAmountType.PER_RECEIPT_LINE && !formik.values.lines?.length) {
      formik.setFieldValue('lines', [{ quantity: 1 }]);
    } else {
      formik.setFieldValue('lines', []);
    }
  };

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
    setFormTouched(true);
  };

  const handleDeleteBlob = (id: string) => setBlobs(blobs.filter((blob) => blob.id !== id));
  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map(({ blob }) => blob);
    setBlobs([...blobs, ...newBlobs]);
    if (isUpdate && propertyId && receiptId) {
      const removedBlobs = R.difference(receipt?.blobs || [], blobs)
        .map((blob) => ({ ...blob, action: ModifyActionType.DELETE }));

      updateReceipt({
        id: receiptId,
        propertyId,
        blobs: removedBlobs.concat(newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE }))),
      });
    }
  };

  const receiptLines = formik.values.lines || [];

  const totalAmountSum = R.sum(
    R.map(
      ({ quantity, pricePerUnit }) => parseFloatNumber(quantity, 0) * parseFloatNumber(pricePerUnit, 0),
      receiptLines,
    ),
  );

  useEffect(() => {
    if (contentFileBlobs.length || receiptInitialData?.blobs) {
      const newBlobs = [...(contentFileBlobs || []), ...(receiptInitialData?.blobs || [])];
      const uniqueBlobs = R.uniqBy((blob) => blob.id, [...blobs, ...newBlobs]);
      setBlobs(uniqueBlobs);
      if (contentFileBlobs.length && !isUpdate) {
        formik.setFieldValue('name', uniqueBlobs[0].name);
      }
    }
  }, [contentFileBlobs.length, receiptInitialData]);

  const handleScanReceipt = (blobToScan: HIDBlob) => {
    if (blobs.length) {
      openScanReceiptDialog({
        blob: blobToScan,
        onScanned: (inferenceId, receiptInitialData) => {
          setInferenceId(inferenceId);
          setEnterAmountType(receiptInitialData?.lines?.length
            ? EnterAmountType.PER_RECEIPT_LINE
            : EnterAmountType.FOR_ENTIRE_RECEIPT);
          formik.setValues({
            ...formik.values,
            name: receiptInitialData?.name,
            date: receiptInitialData?.date,
            invoiceNumber: receiptInitialData?.invoiceNumber,
            place: receiptInitialData?.place,
            annotation: receiptInitialData?.annotation,
            amount: receiptInitialData?.amount,
            isImprovement: Boolean(receiptInitialData?.basicImprovementValue),
            lines: receiptInitialData?.lines,
          });
        },
        onCancel: () => setBlobToScan(undefined),
      });
    }
  };

  useUpdateEffect(() => {
    if ((!isUpdate && blobs.length) || (isUpdate && forceScanReceipt)) {
      const firstBlob = R.head(blobs);
      if (firstBlob && firstBlob !== blobToScan && (!preventOpeningScanReceiptDialog || forceScanReceipt)) {
        setBlobToScan(firstBlob);
        handleScanReceipt(firstBlob);
      }
    }
  }, [blobs, isUpdate]);

  return (
    <HomeLayout
      SideColumn={
        <>
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            isLoading={propertyIsLoading || receiptIsLoading}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
          {SuggestionSection}
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getReceiptCategoriesPath, { propertyId }),
          name: t('receipts:receipts_receipts_title'),
        },
        categoryId && {
          link: getPathWithPropertyIdOrInit(getReceiptCategoryPath, { propertyId, categoryId }),
          name: categoryName,
        },
      ].filter(Boolean)}
      title={isUpdate ? t('receipts:receipts_edit_receipt') : t('receipts:receipts_add_receipt')}
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-end"
        rowSpacing={1}
      >
        {isDownMd && (
          <ContentFileViewer
            showFiles
            showImages
            blobs={blobs}
            isLoading={propertyIsLoading || receiptIsLoading}
            variant="inline"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
        )}
        {Boolean(inferenceId) && (
          <Grid item xxs={12}>
            <InfoBox
              description={t('receipts:read_receipt_data_info_description')}
              message={t('receipts:read_receipt_data_info_message')}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <HIDTextField
            required
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : undefined}
            id="name"
            label={t('forms_common:name')}
            value={formik.values.name}
            onBlur={formik.handleBlur('name')}
            onChange={handleSetField('name')}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDFormSelect
            required
            error={Boolean(formik.touched.categoryId && formik.errors.categoryId)}
            helperText={formik.touched.categoryId ? formik.errors.categoryId : undefined}
            items={categories}
            label={t('forms_common:select_category')}
            value={formik.values.categoryId}
            onBlur={formik.handleBlur('categoryId')}
            onChange={handleSetField('categoryId')}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDTextField
            id="invoiceNumber"
            label={t('receipts:receipts_receipt_invoice_number')}
            ref={fieldRefs.invoice_number}
            value={formik.values.invoiceNumber}
            onBlur={formik.handleBlur('invoiceNumber')}
            onChange={handleSetField('invoiceNumber')}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDFormFreeSelect
            items={places}
            label={t('receipts:receipts_receipts_place_column')}
            ref={fieldRefs.place}
            value={formik.values.place || ''}
            onBlur={formik.handleBlur('place')}
            onChange={(_event, value) => formik.setFieldValue('place', value)}
          />
        </Grid>
        <Grid item xxs={6}>
          <HIDFormDatePicker
            required
            error={Boolean((formik.touched.date || formTouched) && formik.errors.date)}
            helperText={(formik.touched.date || formTouched) ? formik.errors.date : undefined}
            label={t('receipts:receipts_receipts_date_column')}
            ref={fieldRefs.date}
            value={formik.values.date ? new Date(formik.values.date) : undefined}
            onBlur={formik.handleBlur('date')}
            onChange={(date) => formik.setFieldValue('date', date?.toISOString())}
          />
        </Grid>
        <Grid item sx={{ marginRight: 'auto' }} xxs={6}>
          <HIDFormFreeSelect
            items={boughtBy}
            label={t('receipts:receipts_purchased_by')}
            value={formik.values.boughtBy}
            onBlur={formik.handleBlur('boughtBy')}
            onChange={(_event, value) => formik.setFieldValue('boughtBy', value)}
          />
        </Grid>
        <Grid item xxs={12}>
          <HIDTextField
            multiline
            id="annotation"
            label={t('common:annotation')}
            value={formik.values.annotation}
            variant="outlined"
            onBlur={formik.handleBlur('annotation')}
            onChange={handleSetField('annotation')}
          />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="subtitle1">
            {t('receipts:receipts_enter_per_line_or_for_entire_amount')}
          </Typography>
        </Grid>
      </Grid>
      <RadioGroup
        sx={{ alignItems: 'flex-start' }}
        value={enterAmountType}
        onChange={handleEnterAmountTypeChange}
      >
        <FormControlLabel
          control={<Radio />}
          label={t('receipts:receipts_per_receipt_line')}
          value={EnterAmountType.PER_RECEIPT_LINE}
        />
        <FormControlLabel
          control={<Radio />}
          label={t('receipts:receipts_for_the_entire_receipt')}
          value={EnterAmountType.FOR_ENTIRE_RECEIPT}
        />
      </RadioGroup>
      <Grid container item>
        {
          isPerReceiptLine
            ? (
              <>
                {
                  receiptLines.map((_line, index) => (
                    <ReceiptLineForm
                      canDelete={receiptLines.length > 1}
                      formik={formik}
                      index={index}
                      key={index}
                      sx={{ marginTop: 2.5 }}
                      touched={formTouched}
                      onDelete={() => formik.setFieldValue('lines', removeByIndex(index, receiptLines))}
                    />
                  ))
                }
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ marginTop: 2 }}
                  xxs={12}
                >
                  <Grid item>
                    <HIDAddLinkButton
                      label={t('receipts:receipts_add_receipt_line')}
                      onClick={() => formik.setFieldValue('lines', [...receiptLines, {}])}
                    />
                  </Grid>
                </Grid>
                <ReceiptLinesTotalAmount
                  amountRef={fieldRefs.amount}
                  customAmount={formik.values.amount}
                  sumAmount={totalAmountSum}
                  onBlur={formik.handleBlur('amount')}
                  onChange={(amount?: string) => formik.setFieldValue('amount', amount)}
                />
              </>
            )
            : (
              <ManualAmount
                amountRef={fieldRefs.amount}
                formik={formik}
                sx={{ marginTop: 2.5 }}
              />
            )
        }
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={receiptIsLoading}
        loading={receiptIsCreating || receiptIsUpdating}
        showAddConnections={!isUpdate && !hasConnections}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateReceipt;
