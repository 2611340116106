import qs from 'query-string';
import { PropertyType } from '@house-id/houseid-types/dist/property';
import { PropertyValuation } from '@house-id/houseid-types/dist/finances/valuation';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import { propertyApi } from '../../../../../api/api.property';
import { DynamicInputField } from '../../../../Content/modules/DynamicContent/types/types.dynamicContent';
import {
  IndicationValuationParams,
  IndicativeValuation,
  IndicativeValuationHistoryItem,
  IndicativeValuationSettings,
} from '../types.valuation';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';
import { CreateEntity } from '../../../../../../../types/common';

export type CreateIndicationValuationRequest = PropertyId & { data: IndicationValuationParams, propertyType: PropertyType };

export const propertyValuationApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyValuations: builder.query<Array<PropertyValuation>, PropertyId>({
      query: ({ propertyId }) => `/v2/properties/${propertyId}/finance/valuations`,
      providesTags: (result) => provideArrayTags(HIDApiTags.PROPERTY_VALUATION, result),
      transformResponse: (response: { valuations: Array<PropertyValuation> }) => response.valuations,
    }),
    createPropertyValuation: builder.mutation<PropertyValuation, CreateEntity<PropertyValuation> & PropertyId>({
      query: (propertyValuation) => ({
        url: `/v2/properties/${propertyValuation.propertyId}/finance/valuations`,
        method: 'POST',
        body: propertyValuation,
      }),
      transformResponse: (response: { valuation: PropertyValuation }) => response.valuation,
      invalidatesTags: () => [HIDApiTags.PROPERTY_VALUATION],
    }),
    updatePropertyValuation: builder.mutation<PropertyValuation, Partial<PropertyValuation> & PropertyId & HIDEntityId>({
      query: (propertyValuation) => ({
        url: `/v2/properties/${propertyValuation.propertyId}/finance/valuations/${propertyValuation.id}`,
        method: 'PATCH',
        body: propertyValuation,
      }),
      transformResponse: (response: { valuation: PropertyValuation }) => response.valuation,
      invalidatesTags: (_result, _error, arg) => [{ type: HIDApiTags.PROPERTY_VALUATION as const, id: arg.id }],
    }),
    getIndicativeValuationForm: builder.query<Array<Array<DynamicInputField>>, PropertyId & { propertyType: PropertyType; }>({
      query: ({ propertyType, propertyId }) => {
        const dynamicContentId = propertyType === PropertyType.HOUSE
          ? 'movesta_valuation_indication_house_v3'
          : 'movesta_valuation_indication_condominium_v3';

        return `/dynamic-content/${dynamicContentId}?${qs.stringify({ propertyId })}`;
      },
      transformResponse: (response: { content: Array<Array<DynamicInputField>> }) => response.content,
    }),
    getIndicationValuationHistory: builder.query<Array<IndicativeValuationHistoryItem>, PropertyId>({
      query: ({ propertyId }) => `/partner/movesta/valuation/history?${qs.stringify({ propertyId })}`,
      providesTags: [HIDApiTags.PROPERTY_INDICATION_VALUATION_HISTORY],
    }),
    getLatestIndicationValuation: builder.query<IndicativeValuation, PropertyId>({
      query: ({ propertyId }) => `/partner/movesta/valuation/latest?${qs.stringify({ propertyId })}`,
      transformResponse: (result: { valuation: IndicativeValuation }) => result.valuation,
      providesTags: [HIDApiTags.PROPERTY_INDICATION_VALUATION],
    }),
    getIndicationValuationSettings: builder.query<IndicativeValuationSettings, PropertyId>({
      query: ({ propertyId }) => `/partner/movesta/settings?${qs.stringify({ propertyId })}`,
      transformResponse: (result: { settings: IndicativeValuationSettings }) => result.settings,
      providesTags: [HIDApiTags.PROPERTY_INDICATION_VALUATION_SETTINGS],
      transformErrorResponse: (response, meta) => meta?.response?.status === 404
        ? undefined
        : response,
    }),
    patchIndicationValuationSettings: builder.mutation<IndicativeValuationSettings, PropertyId & Partial<IndicativeValuationSettings>>({
      query: ({ propertyId, ...rest }) => ({
        url: `/partner/movesta/settings?${qs.stringify({ propertyId })}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: () => [HIDApiTags.PROPERTY_INDICATION_VALUATION_SETTINGS],
    }),
    createIndicationValuation: builder.mutation<IndicativeValuationSettings, CreateIndicationValuationRequest>({
      query: ({ propertyId, propertyType, data }) => {
        const actionType = propertyType === PropertyType.HOUSE
          ? 'movesta-valuation-indication-house'
          : 'movesta-valuation-indication-condominium';

        return {
          url: `/partner/movesta/${actionType}?${qs.stringify({ propertyId })}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_FINANCE_INFORMATION,
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION,
        HIDApiTags.PROPERTY_INDICATION_VALUATION,
        HIDApiTags.PROPERTY_INDICATION_VALUATION_HISTORY,
      ],
    }),
    deleteIndicationValuation: builder.mutation<IndicativeValuationSettings, PropertyId & Partial<IndicativeValuationSettings>>({
      query: ({ propertyId, ...rest }) => ({
        url: `/partner/movesta/valuation?${qs.stringify({ propertyId })}`,
        method: 'DELETE',
        body: rest,
      }),
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_FINANCE_INFORMATION,
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION,
        HIDApiTags.PROPERTY_INDICATION_VALUATION,
        HIDApiTags.PROPERTY_INDICATION_VALUATION_HISTORY,
      ],
    }),
    getBookValuationInfoForm: builder.query<Array<DynamicInputField>, PropertyId>({
      query: ({ propertyId }) => `/dynamic-content/svenskfast_book_valuation?${qs.stringify({ propertyId })}`,
      providesTags: () => [HIDApiTags.PROPERTY_VALUATION_BOOK_INFO_FORM],
      transformResponse: (response: { content: Array<DynamicInputField> }) => response.content,
    }),
    saveBookValuationInfoForm: builder.mutation<void, PropertyId & { info: object }>({
      query: ({ propertyId, info }) => ({
        url: `/partner/svenskfast/lead/valuation?${qs.stringify({ propertyId })}`,
        method: 'POST',
        body: info,
      }),
      invalidatesTags: () => [HIDApiTags.PROPERTY_VALUATION_BOOK_INFO_FORM],
    }),
  }),
});

export const {
  useGetPropertyValuationsQuery,
  useCreatePropertyValuationMutation,
  useUpdatePropertyValuationMutation,
  useGetIndicativeValuationFormQuery,
  useGetIndicationValuationHistoryQuery,
  useGetLatestIndicationValuationQuery,
  useGetIndicationValuationSettingsQuery,
  usePatchIndicationValuationSettingsMutation,
  useCreateIndicationValuationMutation,
  useDeleteIndicationValuationMutation,
  useGetBookValuationInfoFormQuery,
  useSaveBookValuationInfoFormMutation,
} = propertyValuationApi;
