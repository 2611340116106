import React, { FC } from 'react';
import {
  Divider,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import { useNavigateBackOr } from '../../../../../../utils/routes';
// import Loan from '../../../../../../components/icons/Loan';
import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import HIDTypography from '../../../../../../components/HIDTypography';
// import FinanceOverviewWidget from './components/FinanceOverviewWidget';
import RecurringExpensesOverview from './components/RecurringExpensesOverview';
import BudgetsOverviewWidget from './components/BudgetsOverviewWidget';
import ValuationOverviewWidget from './components/ValuationOverviewWidget';
import { hidSpacing } from '../../../../../../utils/number';

const FinancesOverview: FC = () => {
  const { t } = useTranslation(['finances', 'home']);

  const { isDownSm } = useBreakPointsSizes();

  const navigateBackOr = useNavigateBackOr();

  const { data: propertyId } = useGetCurrentPropertyId();
  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const widgetsList = [
    <RecurringExpensesOverview showActions showIcons key="expense" />,
    <BudgetsOverviewWidget showActions showIcons key="budget" />,
    <ValuationOverviewWidget key="valuation" />,
    // <FinanceOverviewWidget
    //   Icon={Loan}
    //   action={t('finances:get_started_in_1_min')}
    //   description={t('finances:learn_more_about_your_mortgage')}
    //   iconBackgroundColor={theme.palette.info.lighter}
    //   iconColor={theme.palette.info.dark}
    //   key="loan"
    //   title={t('finances:get_a_handle_on_your_mortgages')}
    //   widgetBackground={theme.palette.info.lightest}
    //   onClick={() => { }}
    // />,
  ];

  const MobileDivider = (
    <Divider
      sx={{
        width: `calc(100% + ${hidSpacing(2)}px)`,
        marginTop: 2,
      }}
    />
  );

  return (
    <HomeLayout
      title={t('finances:finances')}
      onBack={handleGoBack}
    >
      <HIDTypography sx={{ mb: isDownSm ? 2 : 4 }}>
        {t('finances:get_complete_control_of_your_accommodation_finances')}
      </HIDTypography>
      {propertyId !== undefined && (
        <Grid
          container
          direction="row"
          spacing={2}
        >
          {widgetsList.map((widget, index) => (
            <React.Fragment key={index}>
              {isDownSm && MobileDivider}
              <Grid
                item
                alignItems="stretch"
                md={6}
                xxs={12}
              >
                {widget}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </HomeLayout>
  );
};

export default FinancesOverview;
