import React, {
  FC,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import ReactMarkdown from 'react-markdown';

import {
  useGetBookValuationInfoFormQuery,
  useSaveBookValuationInfoFormMutation,
} from '../api/api.valuation';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import DynamicContentInputForm from '../../../../Content/modules/DynamicContent/components/dynamicInputForm/DynamicContentInputForm';
import { useNavigateBackOr } from '../../../../../../../utils/routes';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { getValuationOverviewPath } from '../navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { getFinancesPath } from '../../../navigation.finances';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { SF_PRIVACY_POLICY } from '../../../../Content/modules/Insurance/constants.insurance';
import { enqueueSnackbar } from 'notistack';

const BookValuationMeeting: FC = () => {
  const navigateBackOr = useNavigateBackOr();

  const { t } = useTranslation(['finances']);

  const { isDownXl, isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: fields,
    isLoading: formIsLoading,
  } = useGetBookValuationInfoFormQuery(propertyId ? { propertyId } : skipToken);

  const [
    saveBookValuationInfoForm,
    {
      isLoading: formIsSaving,
    },
  ] = useSaveBookValuationInfoFormMutation();

  const [formInfo, setFormInfo] = useState<object>({});
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }));

  const handleSubmit = () => {
    if (formIsValid && propertyId) {
      saveBookValuationInfoForm({ propertyId, info: formInfo })
        .unwrap()
        .then(() => {
          enqueueSnackbar(t('finances:book_valuation_meeting_request_sent'), {
            description: t('finances:book_valuation_meeting_request_sent_description'),
            variant: 'success',
          });

          handleGoBack();
        });
    }
  };

  return (
    <HomeLayout
      SideColumn={isDownXl ? undefined : <div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }),
          name: t('finances:valuation'),
        },
      ]}
      title={t('finances:book_a_meeting_with_a_broker')}
      onBack={handleGoBack}
    >
      <Stack direction="column" gap={4}>
        <HIDTypography>
          {t('finances:book_valuation_meeting_description')}
        </HIDTypography>
        <DynamicContentInputForm
          validateOnMount
          fields={fields || []}
          onIsValidChanged={setFormIsValid}
          onValuesChanged={setFormInfo}
        />
        <ReactMarkdown
          components={{
            p: 'span',
            a: ({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              node,
              href,
              children,
              ...props
            }) => (
              <a
                href={href}
                rel="noreferrer"
                target="_blank"
                {...props}
              >
                {children}
              </a>
            ),
          }}
        >
          {t('finances:book_valuation_sf_privacy_policy', { url: SF_PRIVACY_POLICY })}
        </ReactMarkdown>
        <Stack direction="row" justifyContent={isDownSm ? 'center' : 'flex-end'}>
          <HIDButton
            disabled={formIsLoading || formIsSaving}
            loading={formIsSaving}
            onClick={handleSubmit}
          >
            {t('finances:valuation_send_request')}
          </HIDButton>
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default BookValuationMeeting;
