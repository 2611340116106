import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertyValuation } from '@house-id/houseid-types/dist/finances/valuation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';
import { AttachFile } from '@mui/icons-material';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyValuationsQuery } from '../api/api.valuation';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import HIDLink from '../../../../../../../components/HIDLink';
import { FCC } from '../../../../../../../types/common';
import { BorderRadius } from '../../../../../../../constants/borderRadius';
import {
  getCreateBrokerValuationPath,
  getUpdateBrokerValuationPath,
  getValuationOverviewPath,
} from '../navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { useGetPropertyRealtorsQuery } from '../../../../../api/api.onboarding';
import { OTHER_VALUATION_REALTOR_CODE } from '../constants.valuation';
import ViewEntityActions from '../../../../Content/components/actions/ViewEntityActions';
import { getFinancesPath } from '../../../navigation.finances';

type BrokerValuationCardProps = {
  valuation: PropertyValuation;
  onUpdate: () => void;
};

const BrokerValuationCard: FCC<BrokerValuationCardProps> = ({ valuation, onUpdate, sx }) => {
  const { t } = useTranslation(['common', 'forms_common', 'finances']);
  const theme = useTheme();

  const { data: realtors } = useGetPropertyRealtorsQuery();

  const brokerName = valuation.realtorId === OTHER_VALUATION_REALTOR_CODE
    ? valuation.brokerCompany
    : realtors?.find(({ id }) => id === valuation.realtorId)?.name;

  const numberOfAttachments = valuation?.connections?.length || 0;

  return (
    <Stack
      sx={{
        padding: 2.5,
        borderRadius: BorderRadius.sm,
        backgroundColor: theme.palette.grey[100],
        ...sx,
      }}
    >
      <Stack gap={0.5} key={valuation.id}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <HIDTypography variant="h5">
            {formatMoney(valuation.amount, false)}
          </HIDTypography>
          <HIDLink
            label={t('forms_common:change')}
            onClick={onUpdate}
          />
        </Stack>
        {Boolean(valuation.brokerName) && (
          <Stack direction="row" gap={1}>
            <HIDTypography variant="subtitle1">
              {t('finances:broker')}
            </HIDTypography>
            <HIDTypography variant="body1">
              {valuation.brokerName}
            </HIDTypography>
          </Stack>
        )}
        {Boolean(brokerName) && (
          <Stack direction="row" gap={1}>
            <HIDTypography variant="subtitle1">
              {t('finances:brokerage_company')}
            </HIDTypography>
            <HIDTypography variant="body1">
              {brokerName}
            </HIDTypography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between">
          <HIDTypography variant="subtitle1">
            {formatDate(new Date(valuation.date), DateTimeFormats.DATE_ONLY)}
          </HIDTypography>
          {numberOfAttachments > 0 && (
            <Stack direction="row" gap={0.5}>
              <AttachFile sx={{ color: theme.palette.grey[500] }} />
              <HIDTypography sx={{ color: theme.palette.grey[500] }}>
                {`${t('common:connections')} (${numberOfAttachments})`}
              </HIDTypography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const BrokerValuations: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: valuations,
    isLoading,
  } = useGetPropertyValuationsQuery(propertyId ? { propertyId } : skipToken);

  const handleUpdate = (id: string) => navigate(getPathWithPropertyIdOrInit(getUpdateBrokerValuationPath, { id, propertyId }));

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateBrokerValuationPath, { propertyId }));

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ViewEntityActions
            onAdd={handleAdd}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }),
          name: t('finances:valuation'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={
        [
          <ViewEntityActions key={ViewEntityActions.name} onAdd={handleAdd} />,
        ]
      }
      title={t('finances:broker_valuation')}
    >
      <Stack sx={{ gap: 4 }}>
        <HIDTypography>
          {t('finances:broker_valuation_description')}
        </HIDTypography>
        <Stack sx={{ gap: 2 }}>
          {
            valuations
              ?.map((valuation) => (
                <BrokerValuationCard
                  key={valuation.id}
                  valuation={valuation}
                  onUpdate={() => handleUpdate(valuation.id)}
                />
              ))
          }
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default BrokerValuations;
