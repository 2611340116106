import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  useTheme,
} from '@mui/material';

import BarChart from '../../../../../../../components/icons/BarChart';
import FinancesEmptyStateCard from '../../../components/FinancesEmptyStateCard';
import TrendChartLowPositive from './charts/TrendChartLowPositive';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../hooks/useDialog';
import { useCreateIndicationValuationMutation } from '../api/api.valuation';
import useGetCurrentProperty from '../../../../../hooks/useCurrentProperty';
import { IndicationValuationParams } from '../types.valuation';

const ValuationOverviewEmptyStateCard: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  const { data: currentProperty } = useGetCurrentProperty();

  const [createIndicationValuation, {
    isLoading: isCreatingIndicationValuation,
  }] = useCreateIndicationValuationMutation();

  const [openIndicationValuationDialog] = useDialog(DialogNames.VALUATION_INDICATION_WIZARD_DIALOG);

  const handleFinishIndication = (valuationData: IndicationValuationParams) => {
    if (currentProperty) {
      createIndicationValuation({
        propertyId: currentProperty.id,
        propertyType: currentProperty.type,
        data: valuationData,
      });
    }
  };

  return (
    <FinancesEmptyStateCard
      Icon={BarChart}
      addTitle={t('finances:get_value_indication')}
      color={theme.palette.primary}
      isLoading={isLoading || isCreatingIndicationValuation}
      subTitle={t('finances:get_a_value_indication_and_track_your_homes_approximate_value')}
      title={t('finances:get_a_digital_value_indication')}
      onAdd={() => openIndicationValuationDialog({ onDone: handleFinishIndication })}
    >
      <Stack sx={{ width: '100%', height: 200 }}>
        <TrendChartLowPositive stopOpacity={0} />
      </Stack>
    </FinancesEmptyStateCard>
  );
};

export default ValuationOverviewEmptyStateCard;
