import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  PropertyFinancesValuationSectionData,
  PropertyProgressCategorySectionProps,
} from '../../../types.propertyProgress';
import PropertyProgressCategorySectionContainer from '../../../components/PropertyProgressCategorySectionContainer';
import PropertyProgressCategorySectionContent from '../../../components/PropertyProgressCategorySectionContent';
import ValuationWidget from '../../../../../pages/PropertyHome/components/ValuationWidget';
import {
  getCreateBrokerValuationPath,
  getValuationOverviewPath,
} from '../../../../Finances/modules/Valuation/navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';

const PropertyFinancesValuationSection: FC<PropertyProgressCategorySectionProps<PropertyFinancesValuationSectionData>> = ({
  section,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['forms_common', 'property']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateValuation = () => navigate(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }));

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateBrokerValuationPath, { propertyId }));

  return (
    <PropertyProgressCategorySectionContainer
      Content={
        <PropertyProgressCategorySectionContent
          addButtonText={t('property:property_progress_property_valuation_section_add_valuation')}
          viewAllButtonText={t('property:property_progress_property_valuation_section_view_valuations')}
          onAddClick={handleAdd}
          onViewAllClick={handleNavigateValuation}
        >
          <ValuationWidget />
        </PropertyProgressCategorySectionContent>
      }
      key={section.id}
      section={section}
    />
  );
};

export default PropertyFinancesValuationSection;
