import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import HIDSection from '../../../../../../../components/HIDSection';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HomePrizes from '../../../../../../../components/icons/HomePrizes';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { useGetPropertyFinanceInformationQuery } from '../../../api/api.propertyFinance';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { formatMoney } from '../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import HIDLink from '../../../../../../../components/HIDLink';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import { getNoWrapStyle } from '../../../../../../../utils/style';
import {
  getBookValuationMeetingPath,
  getBrokerValuationsPath,
  getCreateBrokerValuationPath,
} from '../navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';

const RealtorCard: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation(['common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeInformation,
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const realtorValuation = financeInformation?.valuations?.realtor;
  const realtorValuationAmount = realtorValuation?.amount || 0;

  const [openBrokerVariationDialog] = useDialog(DialogNames.BROKER_VALUATION_DIALOG);

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateBrokerValuationPath, { propertyId }));

  const handleSfBook = () => navigate(getPathWithPropertyIdOrInit(getBookValuationMeetingPath, { propertyId }));

  const handleBookNew = () => openBrokerVariationDialog({
    onBook: handleSfBook,
  });

  const handleManage = () => navigate(getPathWithPropertyIdOrInit(getBrokerValuationsPath, { propertyId }));

  const { isDownXl, isDownLg, isDownSm } = useBreakPointsSizes();

  const SectionIcon = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 2,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <HomePrizes iconColor={theme.palette.primary.main} size={32} />
    </Stack>
  );

  const Price = (
    <Stack direction="column" gap={0.5}>
      <HIDTypography>
        {t('finances:valuation_source_broker_valuation')}
      </HIDTypography>
      <HIDTypography variant="h5">
        {formatMoney(realtorValuationAmount, false)}
      </HIDTypography>
    </Stack>
  );

  const Realtor = (
    <Stack direction="column" gap={0.5}>
      {realtorValuation?.brokerName && (
        <HIDTypography sx={getNoWrapStyle(1)}>
          {realtorValuation?.brokerName}
        </HIDTypography>
      )}
      {realtorValuation?.brokerCompany && (
        <HIDTypography sx={getNoWrapStyle(1)}>
          {realtorValuation?.brokerCompany}
        </HIDTypography>
      )}
    </Stack>
  );

  const ValuationDate = realtorValuation?.date !== undefined
    ? (
      <HIDTypography>
        {formatDate(new Date(realtorValuation?.date), DateTimeFormats.DATE_ONLY_TEXT)}
      </HIDTypography>
    )
    : undefined;

  const BookLink = (
    <HIDLink
      label={t('finances:book_an_appointment_for_a_new_valuation')}
      // @ts-expect-error
      labelStyle={{ ...getNoWrapStyle(1), display: 'block' }}
      onClick={handleBookNew}
    />
  );

  const ManageButton = isDownXl && !isDownLg
    ? (
      <HIDIconButton
        Icon={Edit}
        color="white"
        title={t('common:manage')}
        onClick={handleManage}
      />
    )
    : (
      <HIDButton
        color="secondary"
        sx={{
          alignSelf: isDownSm ? undefined : 'center',
          width: isDownSm ? 180 : undefined,
        }}
        onClick={handleManage}
      >
        {t('common:manage')}
      </HIDButton>
    );

  const AddButton = (
    <HIDButton color="primary" sx={{ minWidth: isDownSm ? 200 : undefined }} onClick={handleAdd}>
      {t('finances:add_it_here')}
    </HIDButton>
  );

  const EmptyStateText = (
    <HIDTypography style={{ whiteSpace: 'pre-wrap' }} variant="h6">
      {t('finances:valuation_done_via_broker')}
    </HIDTypography>
  );

  const EmptyState = isDownSm
    ? (
      <Stack
        alignItems="center"
        direction="column"
        sx={{ gap: 2.5 }}
      >
        {SectionIcon}
        {EmptyStateText}
        {AddButton}
      </Stack>
    )
    : (
      <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
        {SectionIcon}
        {EmptyStateText}
        <Stack sx={{ flexShrink: 0, marginLeft: 'auto' }}>
          {AddButton}
        </Stack>
      </Stack>
    );

  const FilledState = isDownLg
    ? (
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        justifyContent="space-between"
        sx={{ gap: 2.5 }}
      >
        <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
          {SectionIcon}
          <Stack direction="column">
            {Price}
            {Realtor}
            {ValuationDate}
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2, alignSelf: 'center', minWidth: 0 }}>
          {ManageButton}
          {BookLink}
        </Stack>
      </Stack>
    )
    : (
      <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
        {SectionIcon}
        <Stack direction="column">
          {Price}
          {ValuationDate}
        </Stack>
        <Stack direction="column" sx={{ marginLeft: isDownXl ? 0 : 4.5, minWidth: 0 }}>
          {Realtor}
          {BookLink}
        </Stack>
        <Stack sx={{ marginLeft: 'auto' }}>
          {ManageButton}
        </Stack>
      </Stack>
    );

  return (
    <HIDSection>
      {
        realtorValuationAmount
          ? FilledState
          : EmptyState
      }
    </HIDSection>
  );
};

export default RealtorCard;
