import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  NorthEastRounded,
  SouthEastRounded,
} from '@mui/icons-material';

import HIDTypography from '../../../../../../../components/HIDTypography';
import { getValuationDifferencePercent } from '../utils.valuation';
import { formatMoney } from '../../../../../../../utils/string';
import { formatPercentage } from '../../../../../../../utils/number';
import ValuationTrendChart from './ValuationTrendChart';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import LoadingIcon from '../../../../../../../components/icons/LoadingIcon';

type ValuationResultCardProps = {
  isLoading: boolean;
  updatedAt?: string;
  brokerText?: string;
  marketPrice: number;
  purchasePrice: number;
};

const ValuationResultCard: FC<ValuationResultCardProps> = ({
  isLoading,
  brokerText,
  updatedAt,
  marketPrice,
  purchasePrice,
}) => {
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();
  const { t } = useTranslation(['common', 'finances']);

  const valuationPercent = getValuationDifferencePercent(marketPrice, purchasePrice);

  const difference = marketPrice ? marketPrice - purchasePrice : marketPrice;

  return (
    <Stack
      alignItems="center"
      direction="row"
      flex={1}
      gap={isDownSm ? 2 : 4}
      justifyContent="space-between"
    >
      <Stack
        direction="column"
        flex={isDownSm ? 1 : undefined}
        gap={0.5}
        sx={{ minWidth: 224 }}
      >
        <HIDTypography isLoading={isLoading} skeletonWidth={200} variant="subtitle1">
          {t('finances:development_since_purchase')}
        </HIDTypography>
        <HIDTypography isLoading={isLoading} skeletonWidth={200} variant="h5">
          {formatMoney(difference)}
        </HIDTypography>
        {(marketPrice > 0 || isLoading) && (
          <Stack alignItems="center" direction="row" gap={0.5}>
            {
              isLoading
                ? <LoadingIcon size={20} />
                : valuationPercent > 0
                  ? <NorthEastRounded height={20} sx={{ color: theme.palette.primary.main }} width={20} />
                  : <SouthEastRounded height={20} sx={{ color: theme.palette.error.main }} width={20} />
            }
            <HIDTypography
              color={valuationPercent > 0 ? theme.palette.primary.main : theme.palette.error.main}
              isLoading={isLoading}
              skeletonWidth={200}
              variant="subtitle1"
            >
              {formatPercentage(valuationPercent, { wholeNumber: true })}
            </HIDTypography>
          </Stack>
        )}
        {(Boolean(updatedAt || brokerText) || isLoading) && (
          <Stack>
            {Boolean(brokerText) && (
              <HIDTypography isLoading={isLoading} variant="body1">
                {brokerText}
              </HIDTypography>
            )}
            <Stack direction="row" flexWrap="wrap">
              <HIDTypography
                isLoading={isLoading}
                skeletonWidth={100}
                style={{ color: theme.palette.grey[500] }}
                variant="body1"
              >
                {t('common:updated_at')}
              </HIDTypography>
              <span>&nbsp;</span>
              <HIDTypography
                isLoading={isLoading}
                skeletonWidth={100}
                style={{ color: theme.palette.grey[500] }}
                variant="body1"
              >
                {formatDate(new Date(updatedAt as string), DateTimeFormats.DATE_ONLY)}
              </HIDTypography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack flex={1} sx={{ overflow: 'hidden' }}>
        <ValuationTrendChart height={96} percent={valuationPercent * 100} />
      </Stack>
    </Stack>
  );
};

export default ValuationResultCard;
